import {
  TEXT,
  LONG_TEXT,
  INTEGER,
  DATE,
  SELECT,
  NUMERIC,
  ENUM,
  MULTIPLE_SELECT,
  DATE_TIME,
  TIME,
  BOOLEAN,
} from './dynamicFormColumnTypes';

export const REACT_APP_VERSION = process.env.REACT_APP_VERSION;
export const REACT_APP_ENV = process.env.REACT_APP_ENV || 'development';
export const IS_PRODUCTION = process.env.NODE_ENV === 'production';
export const AUTH_TOKEN = 'auth:token';
export const ROLES = {
  VIEWER: 'viewer',
  EDITOR: 'editor',
  OWNER: 'owner',
  ADMIN: 'admin',
};
export const IS_MAC = /Mac|iPod|iPhone|iPad/.test(navigator.platform);
export const LOGIN_REDIRECT_WHITE_LIST =
  process.env.REACT_APP_LOGIN_REDIRECT_WHITE_LIST || 'localhost:3000,app.cowsquare.com,hkdu.cowsquare.com';
export const FILTERABLE_TYPES = [
  TEXT,
  LONG_TEXT,
  INTEGER,
  DATE,
  SELECT,
  ENUM,
  NUMERIC,
  MULTIPLE_SELECT,
  DATE_TIME,
  TIME,
  BOOLEAN,
];
