import * as React from 'react';
import * as ReactDOM from 'react-dom';
import { ApolloProvider } from 'react-apollo';

import App from 'views/App';
import { client } from 'apollo/client';
import { register } from './registerServiceWorker';
import { createGlobalStyle } from 'styled-components';
import { transparentize } from 'polished';
import { COLOR } from 'views/styleVariable';
import { postToGTM } from 'utils/gtm';
import { REACT_APP_ENV, REACT_APP_VERSION } from 'constants/index';
import { REACT_RENDER } from 'constants/gtmEventTypes';

if (process.env.NODE_ENV === 'development') {
  const whyDidYouRender = require('@welldone-software/why-did-you-render');
  whyDidYouRender(React, {});
}

postToGTM({
  event: REACT_RENDER,
  env: REACT_APP_ENV,
  release: REACT_APP_VERSION,
});

const GlobalStyle = createGlobalStyle`
 * {
  ::-webkit-scrollbar {
    width: 10px;
    height: 10px;
  }

  /* ant design css will load twice in production env and cover the this style, so we need to add class for mark. */
  mark.highlight {
    color: white;
    background-color: ${COLOR.PRIMARY};
  }

  ::-webkit-scrollbar-thumb {
      border: solid 2px transparent;
      border-radius: 5px;
      box-shadow: inset 0 0 0 10px;
      color: ${transparentize(1, COLOR.MENU_ITEM)};
  }

  &:hover {
    ::-webkit-scrollbar-thumb {
      color: ${transparentize(0.1, COLOR.MENU_ITEM)};
    }
  }

  font-family: 'Open Sans', 'Noto Sans TC', sans-serif;
 }

.ant-btn {
  &.ant-btn-primary {
    &:focus {
      background-color: ${COLOR.PRIMARY};
    }
    &:hover {
      background-color: ${COLOR.BUTTON_HOVER_BACKGROUND};
    }
  }
}

.ant-notification {
  .custom-notification {
    width: 380px;
    border-radius: 5px;
    .ant-notification-notice-icon {
      margin-left: 0;
      font-size: 32px;
    }
    .ant-notification-notice-message {
      width: 280px;
      font-size: 16px;
      font-weight: 600;
      color: ${COLOR.TITLE};
      line-height: 1.5;
    }
    .ant-notification-notice-description {
      width: 280px;
      color: ${COLOR.TITLE};
      line-height: 1.5;
    }
    .ant-notification-notice-close {
      right: 16px;
    }
  }
}

`;

ReactDOM.render(
  <ApolloProvider client={client}>
    <App />
    <GlobalStyle />
  </ApolloProvider>,
  document.getElementById('root')
);

register();
